// src/pages/contact.js
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CtrSection from '../components/CtrSection/CtrSection';
import FlowChart from '../components/FlowChart/flowChart';
import PriceingPlan from '../components/PricingPlan/PricingPlan';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const FlowPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="HOMEPAGE｜ホームページ制作"
        title="受付からホームページ制作までの流れ"
        text="受付からホームページ制作までの流れをご紹介します。Q&Aも掲載しています。"
        imageUrl="/images/haikei3.jpg"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/about', crumbLabel: '企業概要' },
          { pathname: '/business', crumbLabel: '事業内容' },
          { pathname: '/flow', crumbLabel: '受付の流れ' },
        ]}
      />
      <FlowChart />
      <PriceingPlan />
      <CtrSection />
    </Layout>
  );
};

export default FlowPage;

export const Head = () => (
  <Seo
    title="受付からホームページ制作までの流れ"
    description="デジタルクリエイティブ株式会社の受付からホームページ制作までの流れをご紹介します。"
    cover="cover/cover-flow.jpg"
  />
);
